import { Component, Input, OnInit } from '@angular/core';
import { FirebaseAuthService } from '../../services/firebase-auth.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.scss']
})
export class TokenComponent implements OnInit {
  public token: string;

  constructor(private readonly firebaseAuthService: FirebaseAuthService) { }

  ngOnInit(): void {
    this.firebaseAuthService.idToken
      .subscribe((value: string) => {
        console.log(value)
        this.token = value;
      });
  }

}
